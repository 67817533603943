import Vue from 'vue'
import App from './app'
import router from '@router'
import store from '@state/store'
import '@components/_globals'

import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VueMask from 'v-mask'
import vco from 'v-click-outside'
import VueRouter from 'vue-router'
import VueFeather from 'vue-feather'
import VuePageTransition from 'vue-page-transition'
import * as Sentry from "@sentry/vue";

Vue.use(VueFeather)

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(VuePageTransition)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(BootstrapVue)
Vue.use(VueMask)

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

import './utils/filters.js'

import { ValidationObserver  } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor';
Vue.use(RichTextEditorPlugin);

import x5GMaps from 'x5-gmaps'
Vue.use(x5GMaps, { key: 'AIzaSyAtRUx4ESGOPjd_OVVwdiSEtKAvy6SXBjw', libraries: ['places', 'drawing'] })

Vue.component('apexchart', VueApexCharts)

Sentry.init({
  Vue,
  dsn: "https://7f24277040b696daad3b0765cd250592@o4508290953445376.ingest.de.sentry.io/4508293478482000",
  integrations: [
    Sentry.browserTracingIntegration({ VueRouter }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Uncomment this if you are having api served through other url or do not want to use fake backend
// Vue.prototype.$http = require('axios')
// Vue.prototype.$http.defaults.baseURL  = 'http://mock-api.coderthemes.com/'

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  window.__app__ = app
}
